'use strict';

var bind = require('function-bind');
var define = require('define-properties');

//var implementation = require('./implementation');
//var getPolyfill = require('./polyfill');
//var shim = require('./shim');

var getPolyfill = function () {
	return Promise.prototype['finally'];
};

var bound = bind.call(Function.call, getPolyfill());

define(bound, {
	getPolyfill: getPolyfill,
	implementation: Promise.prototype['finally'],
	shim: getPolyfill
});

module.exports = bound;
